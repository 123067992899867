import React from "react";
import { connect } from "react-redux";
import scrollToComponent from "react-scroll-to-component";
import { SlideDown } from "react-slidedown";
import styled from "styled-components/macro";
import VisibilitySensor from "react-visibility-sensor";
import { Col, Div, Row } from "../../../../components/grid";
import { Heading3, Paragraph, Span } from "../../../../components/typography";
import {
  anyTabExpanded,
  toggleTab,
  isExpanded,
  hasGoneToTab,
  shouldGotoTab,
} from "../../../../features/tabs";
import IpRestricter from "../../../../lib/components/IpRestricter";
import { FaAngleDown, FaAngleUp } from "../../../../components/Icons";

const CategorySlideDown = styled(({ hidden, ...props }) => (
  <SlideDown {...props} />
))`
  .react-slidedown& {
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }
  ${({ hidden }) => !hidden && "overflow: hidden;"}
`;

const ButtonURL = styled.button`
  background: white;
  border: 1px solid #ccc;
  position: absolute;
  left: -17px;
  top: 0px;
  text-align: center;
  width: 17px;
  font-size: 8px;
  display: block;
  text-transform: uppercase;
  font-family: monospace;
  height: 64px;

  &:active {
    background: ${({ theme }) => theme.colors.colorBlue};
    color: white;
  }
`;

const IconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > svg {
    font-size: 30px;
    fill: ${({ theme }) => theme.colors.colorBlue};
  }
`;

class CategoryDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.categoryRef = React.createRef();
    this.visibilityChange = this.visibilityChange.bind(this);
    this.lastExpansion = 0;
    this.isVisible = false;
  }

  componentDidMount() {
    this.gotoIfNessesary();
  }

  componentWillReceiveProps(nextProps) {
    const { expanded } = nextProps;
    const { expanded: wasExpanded } = this.props;
    if (!wasExpanded && expanded) {
      this.lastExpansion = new Date().getTime();
    }
  }

  componentDidUpdate() {
    this.gotoIfNessesary();
  }

  componentWillUnmount() {
    /*
    const { expanded, toggle } = this.props;
    if (expanded) {
      toggle();
    }
    */
  }

  onToggle = () => {
    const { toggle, anyExpanded } = this.props;
    if (!anyExpanded) {
      this.dontScroll = true;
      setTimeout(() => {
        this.dontScroll = false;
      }, 1500);
    }
    toggle();
  };

  gotoIfNessesary() {
    const { goto, hasGoneTo, name } = this.props;
    console.log("What has goto", name, goto, hasGoneTo);
    if (goto) {
      scrollToComponent(this.categoryRef.current, { align: "top" });
      hasGoneTo();
    }
  }

  visibilityChange(isVisible) {
    const { goto } = this.props;
    const timeDiff = new Date().getTime() - this.lastExpansion;
    this.isVisible = isVisible;
    if (!isVisible && !goto && timeDiff < 1500 && !this.dontScroll) {
      setTimeout(() => {
        scrollToComponent(this.categoryRef.current, {
          align: "top",
          duration: 500,
          offset: -25,
        });
      }, 1500 - timeDiff + 200);
    }
  }

  render() {
    const {
      name,
      expanded,
      headline,
      packaging,
      headlineSmall,
      subHeadline,
      fadoelsType,
      image,
      children,
      alwaysOpen,
      debug,
      categoryURL,
    } = this.props;
    if (debug) {
      console.log("Also inside Cat container");
    }
    return (
      <Div relative>
        <div ref={this.categoryRef}>
          <Row
            bestil
            role={alwaysOpen ? undefined : "button"}
            onClick={alwaysOpen ? undefined : this.onToggle}
            id={`cat_${name}`}
          >
            <Col xs={3} sm={2} md={3} lg={2} xl={1}>
              {image}
            </Col>
            <Col xs={7} sm={8} md={8} lg={9} xl={10}>
              <VisibilitySensor onChange={this.visibilityChange} active>
                <div>
                  <Heading3 marginBottom="0">
                    {headline()}
                    {packaging && (
                      <Span fontSize="13px">&nbsp;&nbsp;{packaging}</Span>
                    )}
                    {headlineSmall && (
                      <React.Fragment>{headlineSmall}</React.Fragment>
                    )}
                  </Heading3>
                  <Paragraph margin="0">{fadoelsType}</Paragraph>
                  <Paragraph margin="0">{subHeadline}</Paragraph>
                </div>
              </VisibilitySensor>
            </Col>
            <Col xs={2} sm={2} md={1} lg={1} xl={1} paddingTop="22px">
              {!alwaysOpen && (
                <React.Fragment>
                  {expanded ? (
                    <IconStyled>
                      <FaAngleUp />
                    </IconStyled>
                  ) : (
                    <IconStyled>
                      <FaAngleDown />
                    </IconStyled>
                  )}
                </React.Fragment>
              )}
            </Col>
          </Row>
        </div>
        <CategorySlideDown hidden={!expanded && !alwaysOpen}>
          {expanded || alwaysOpen ? children : null}
        </CategorySlideDown>
        {categoryURL && (
          <IpRestricter ips="80.63.99.242, 87.104.40.8">
            <ButtonURL
              onClick={() =>
                navigator.clipboard.writeText(
                  `https://skafte.dk/bestil/${categoryURL}/kategori/${name}`
                )
              }
            >
              K<br />o<br />p<br />i<br />e<br />r
            </ButtonURL>
          </IpRestricter>
        )}
      </Div>
    );
  }
}

export default connect(
  (state, { name }) => ({
    expanded: isExpanded(state, name),
    goto: shouldGotoTab(state, name),
    anyExpanded: anyTabExpanded(state),
  }),
  (dispatch, { name }) => ({
    toggle: () => dispatch(toggleTab(name)),
    hasGoneTo: () => dispatch(hasGoneToTab(name)),
  })
)(CategoryDisplay);
