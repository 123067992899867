import React from 'react';
import styled from 'styled-components/macro';
import Modal from './Modal';
import { LinkStyled, Paragraph } from './typography';
import { Price } from '../containers/commerce';
import { BsFillPlusCircleFill } from './Icons';


const StyledModal = styled(Modal)`
  padding: 15px;
  width: 90%;
  max-height: 80%;
  max-width: calc(500px - 30px);
  overflow: visible;

  @media (max-width: 991px) {
    max-height: calc(100% - 140px);
  }

  @media (max-height: 575px) {
    max-height: calc(100% - 120px);
  }
`;
const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.colorRed};
  border: 2px solid ${({ theme }) => theme.colors.colorWhite};
  position: absolute;
  display: flex;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  background: white;
  rotate: 45deg;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorRed};
  }
`;
export default class ImagePopup extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      open: false,
    };
  }

  onClick() {
    this.setState({ open: true });
  }

  onClose() {
    this.setState({ open: false });
  }

  render() {
    const {
      children,
      ...props
    } = this.props;
    const link = (
      <LinkStyled action noStyle onClick={this.onClick} {...props}>
        {children}
      </LinkStyled>
    );
    const { open } = this.state;
    if (open) {
      return (
        <React.Fragment>
          {link}

          <StyledModal
            isOpen
            onRequestClose={this.onClose}
          >
            <Paragraph fontSize="20px" bold>Ubrudte fustager tages retur</Paragraph>
            <Paragraph fontSize="16px">
              Ubrudte fustager tages retur til fuld pris, minus <Price varenr="641" momsIndication /> pr. fustage
            </Paragraph>
            <Paragraph fontSize="16px">
              Skal afleveres senest 14 dage efter køb.
            </Paragraph>
            <CloseButton onClick={this.onClose}><BsFillPlusCircleFill /></CloseButton>
          </StyledModal>
        </React.Fragment>
      );
    }
    return link;
  }
}
