import React from 'react';
import styled from 'styled-components/macro';
import { LinkButton, Heading3, Span } from '../typography';
import Format from '../Format';
import { Row, Col, Div } from '../grid';
import { BsCheckSquare, BsSquare } from '../Icons';

const values = [
  { name: 'Vælg type', value: 1 },
  { name: 'Bryllup', value: 1 },
  { name: 'Reception', value: 0.8 },
  {
    name: 'Jubilæum',
    value: 1.2,
  },
  {
    name: 'Konfirmation',
    value: 2,
  },
  {
    name: 'Åbent hus',
    value: 2.5,
  },
  {
    name: 'Familiefest',
    value: 3.5,
  },
  {
    name: 'Fødselsdagsfest',
    value: 3,
  },
  {
    name: 'Sølvbryllup',
    value: 3,
  },
  {
    name: 'Gymnasiefest',
    value: 4.5,
  },
  {
    name: 'Havefest',
    value: 5,
  },
  {
    name: 'Firmafest',
    value: 5.5,
  },
  {
    name: 'Svendegilde',
    value: 6,
  },
  {
    name: 'Firmajulefrokost',
    value: 7,
  },
  {
    name: 'Studenterfest',
    value: 9,
  },
];
const RenderSelect = styled.select`
  border: 1px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  background: white;
  padding: 10px;
  outline: 0;
  top: -11px;
  position: relative;
  float: right;
  cursor: pointer;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: linear-gradient(45deg,transparent 50%,gray 50%), linear-gradient(135deg,gray 50%,transparent 50%), linear-gradient(135deg,transparent 50%,gray 50%), linear-gradient(45deg,gray 50%,transparent 50%);
  background-position:
      5px calc(100% - 10px),
      10px calc(100% - 10px),
      5px 10px,
      10px 10px
  ;
  background-size: 5px 5px,5px 5px;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    float: none;
    display: block;
    position: static;
    margin-top: 5px;
  }
`;
const RenderInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  width: calc(100% - 22px);
  background: white;
  padding: 10px;
  outline: 0;
  text-align: right;
  top: -11px;
  position: relative;
`;
const CheckBoxComponentNoStyle = ({
  checked,
  onClick,
  children,
  ...otherProps
}) => (
  <Div
    onClick={onClick}
    {...otherProps}
  >
    <div className='text'>{children}</div>
    <div className='icon'>{checked ? <BsCheckSquare /> : <BsSquare />}</div>
  </Div>
);
const CheckBoxComponent = styled(CheckBoxComponentNoStyle)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
  position: relative;
  cursor: pointer;

  & > .icon {
    font-size: 20px;
  }

  & > .text {
    min-height: 32px;
  }

  ${({ checked, theme }) => {
    if (checked) {
      return `
        color: ${theme.colors.colorGreen};
      `;
    }
    return `
      color: ${theme.colors.colorGreyDark};
    `;
  }}
`;
const CheckBoxes = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
`;

export default class FadoelsBeregner extends React.Component {
  constructor(props) {
    super(props);
    this.onCalculate = this.onCalculate.bind(this);
    this.state = {
      partyType: 0,
      numberOfGuests: 100,
      hours: 6,
      onSale: false,
      wine: false,
      liquor: false,
      food: false,
      haner: 0,
      fustager: 0,
      beersPrGuest: 0,
    };
  }

  onCalculate() {
    const {
      partyType,
      numberOfGuests,
      hours,
      onSale,
      wine,
      liquor,
      food,
    } = this.state;
    let othersMultiplier = 1;
    othersMultiplier *= onSale ? 0.8 : 1;
    othersMultiplier *= wine ? 0.8 : 1;
    othersMultiplier *= liquor ? 0.7 : 1;
    othersMultiplier *= food ? 1.1 : 1;

    const partyTypeVal = values[partyType].value;
    const numberOfGuestsVal = (numberOfGuests && numberOfGuests > 0) ? numberOfGuests : 100;
    const hoursVal = (hours && hours > 0) ? hours : 6;

    const regnTil = 1.05 ** (hoursVal - 4);
    const men = Math.ceil(numberOfGuestsVal / 2);
    const women = Math.floor(numberOfGuestsVal / 2);
    let regneGenstande = partyTypeVal * regnTil * othersMultiplier * 1.05;
    regneGenstande *= men + (women * 0.6);

    const regneLiter = regneGenstande / 3;
    const fustager = Math.ceil(regneLiter / 25);
    const regneTimer = hoursVal * 1.25;
    const haner = Math.ceil(fustager / regneTimer);

    const flasker = fustager * 25 * 3;
    const flaskerPrGuest = flasker / numberOfGuestsVal;
    this.setState({
      hours: hoursVal,
      numberOfGuests: numberOfGuestsVal,
      fustager,
      haner,
      beersPrGuest: flaskerPrGuest,
    });
  }

  render() {
    const {
      hours,
      partyType,
      numberOfGuests,
      onSale,
      wine,
      liquor,
      food,
      haner,
      fustager,
      beersPrGuest,
    } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} paddingBottom="0px">
            <Heading3>Fadølsberegner</Heading3>
          </Col>
        </Row>
        <Row borderTop="20px">
          <Col xs={12}>
            <Row>
              <Col xs={6} sm={8} paddingTop="0px">
                Til hvilken type fest/arrangement skal fadøllet bruges?
              </Col>
              <Col xs={6} sm={4} paddingTop="0px">
                <RenderSelect dir="rtl" value={partyType} onChange={({ target: { value } }) => this.setState({ partyType: value })}>
                  {values.map(
                    ({ name }, idx) => <option value={idx} key={idx}>{name}</option>,
                  )}
                </RenderSelect>
              </Col>
              <Col xs={6} sm={8} paddingBottom="0px">
                Hvor mange gæster drikker fadøl?
              </Col>
              <Col xs={6} sm={4} paddingBottom="0px">
                <RenderInput type="number" value={numberOfGuests} onChange={({ target: { value } }) => this.setState({ numberOfGuests: value })} />
              </Col>
              <Col xs={6} sm={8} paddingBottom="0px">
                Hvor mange hele timer varer festen?
              </Col>
              <Col xs={6} sm={4} paddingBottom="0px">
                <RenderInput type="number" value={hours} onChange={({ target: { value } }) => this.setState({ hours: value })} />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <CheckBoxes>
              <CheckBoxComponent checked={onSale} onClick={() => this.setState({ onSale: !onSale })}>
                Fadøllet sælges
              </CheckBoxComponent>
              <CheckBoxComponent checked={wine} onClick={() => this.setState({ wine: !wine })}>
                Der er vin
              </CheckBoxComponent>
              <CheckBoxComponent checked={liquor} onClick={() => this.setState({ liquor: !liquor })}>
                Der er spiritus
              </CheckBoxComponent>
              <CheckBoxComponent checked={food} onClick={() => this.setState({ food: !food })}>
                Der er mad
              </CheckBoxComponent>
            </CheckBoxes>
          </Col>
        </Row>
        <Row>
          <Col xs={12} textCenter>
            <LinkButton action calculator justifyCenter onClick={this.onCalculate}>Beregn behov</LinkButton>
          </Col>
        </Row>
        <Row>
          <Col xs={12} textCenter>
            <Span bold fontSize="20px" textCenter>Anslået behov</Span>
            <Div>
              <Span bold fontSize="20px">{haner}</Span><Span marginRight="10px" marginLeft="10px">hane{haner !== 1 && 'r'}{' '}</Span>
              <Span bold fontSize="20px">{fustager}</Span><Span marginRight="10px" marginLeft="10px">fustage{fustager !== 1 && 'r'}{' '}</Span>
              <Span bold fontSize="20px"><Format decimalScale={1} value={beersPrGuest} /></Span><Span marginRight="10px" marginLeft="10px">øl pr. gæst</Span>
            </Div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
