import React from 'react';
import styled from 'styled-components/macro';
import Modal from '../Modal';
import { LinkStyled } from '../typography';
import FadoelsBeregner from './FadoelsBeregner';
import { ga } from '../../lib/analytics';
import { Div } from '../grid';
import { BsFillPlusCircleFill } from '../Icons';

const StyledModal = styled(Modal)`
overflow-y: visible;
  padding: 15px;
  width: 90%;
  max-width: calc(500px - 30px);

  @media (max-width: 991px) {
    top: 15px !important;
    transform: translate(-50%,10px) !important;
    max-height: calc(100% - 120px);
  }
`;
const ScrollDiv = styled(Div)`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 120px);
`;
const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.colorRed};
  border: 2px solid ${({ theme }) => theme.colors.colorWhite};
  position: absolute;
  display: flex;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  background: white;
  rotate: 45deg;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.hoverColors.colorRed};
  }
`;

export default class FadoelsBeregnerPopupLink extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = {
      open: props.initialyOpen || false,
    };
  }

  onClick() {
    this.setState({ open: true });
    ga('event', 'open', { event_category: 'fadoelberegner' });
  }

  onClose() {
    this.setState({ open: false });
    ga('event', 'close', { event_category: 'fadoelberegner' });
  }

  render() {
    const {
      name,
      ...props
    } = this.props;
    const link = <LinkStyled action noStyle {...props} onClick={this.onClick} />;
    const { open } = this.state;
    if (open) {
      return (
        <React.Fragment>
          {link}
          <StyledModal
            isOpen
            onRequestClose={this.onClose}
          >
            <ScrollDiv>
              <FadoelsBeregner onClose={this.onClose} />
            </ScrollDiv>
            <CloseButton onClick={this.onClose}><BsFillPlusCircleFill /></CloseButton>
          </StyledModal>
        </React.Fragment>
      );
    }
    return link;
  }
}
