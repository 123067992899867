import React from 'react';
import CategoryContainer from './CategoryContainer';
import { pageCategories, categories } from '../../../../content/commerce/itemCategories';
import ListItem from './ListItem';

export default ({ page, subPage, debug }) => {
  const subPageInfo = pageCategories[page].subPages[subPage];
  const {
    categories: subPageCategories,
  } = subPageInfo;
  return subPageCategories.map(
    (subPageCategory) => {
      const category = categories[subPageCategory];
      if (!category) {
        return null;
      }
      const {
        name,
        headline,
        packaging,
        headlineSmall,
        subHeadline,
        fadoelsType,
        image,
        items,
        categoryURL,
        alwaysOpen,
        beforeItem = {},
        afterItem = {},
        itemProps = {},
      } = category;
      const itemViews = items.map(
        (varenr) => {
          const before = beforeItem[varenr];
          const after = afterItem[varenr];
          const extraProps = itemProps[varenr] || {};
          return (
            <React.Fragment>
              {before}
              <ListItem varenr={varenr} {...extraProps} key={varenr} allowExpiredKeepDisplay />
              {after}
            </React.Fragment>
          );
        },
      );
      if (!headline) {
        return itemViews;
      }
      return (
        <CategoryContainer
          name={name}
          headline={headline}
          headlineSmall={headlineSmall}
          subHeadline={subHeadline}
          image={image}
          alwaysOpen={alwaysOpen}
          fadoelsType={fadoelsType}
          debug={debug}
          packaging={packaging}
          categoryURL={categoryURL}
        >
          {itemViews}
        </CategoryContainer>
      );
    },
  );
};
