import { useState, useEffect, useMemo } from "react";
import getArg from "../args";

// Function to fetch IP Address from ipify API
function fetchIpAddress(done) {
  // If running on the server, return localhost IP
  if (getArg("serverDirect") || typeof window === "undefined") {
    done("127.0.0.1");
    return;
  }

  // Check if IP is cached in window object
  if (window.userIpAddress) {
    done(window.userIpAddress);
    return;
  }

  // Fetch IP from ipify
  fetch("https://api.ipify.org?format=json", { mode: "cors" })
    .then((resp) => resp.json())
    .then((ip) => {
      window.userIpAddress = ip.ip; // Cache IP
      done(window.userIpAddress);
    })
    .catch((err) => {
      console.error("Failed to fetch IP:", err);
      done(null);
    });
}

// IP Restricter Component
export default ({ ips = [], children }) => {
  // Parse and memoize allowed IPs
  const actualIps = useMemo(() => {
    if (typeof ips === "string") {
      return ips.split(",").map((ip) => ip.trim());
    }
    if (Array.isArray(ips)) {
      return ips;
    }
    return [];
  }, [ips]);

  const [currentIp, setCurrentIp] = useState(null);

  useEffect(() => {
    // Clear cached IP and fetch again
    window.userIpAddress = null;
    fetchIpAddress((ip) => {
      setCurrentIp(ip);
    });
  }, []);

  useEffect(() => {
    console.log("Current IP:", currentIp);
    console.log("Allowed IPs:", actualIps);
  }, [currentIp]);

  // Allow local development
  const localIps = ["127.0.0.1", "::1"];
  if (localIps.includes(currentIp) || actualIps.includes(currentIp)) {
    return children;
  }

  // Default: Block access if IP not allowed
  return null;
};
