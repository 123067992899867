import React from 'react';
import { connect } from 'react-redux';
import scrollToComponent from 'react-scroll-to-component';
import { shouldGotoVare, hasGoneToVare, isHighlighted } from '../../../../features/tabs';
import { Item } from '../../../../containers/commerce';

// eslint-disable-next-line react/prefer-stateless-function
class RefItem extends React.Component {
  render() {
    return <Item {...this.props} />;
  }
}
class ListItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();
  }

  componentDidMount() {
    this.gotoIfNessesary();
  }

  componentDidUpdate() {
    this.gotoIfNessesary();
  }

  gotoIfNessesary() {
    const { goto, hasGoneTo } = this.props;
    if (goto) {
      scrollToComponent(this.itemRef.current, { align: 'top', offset: -100 });
      hasGoneTo();
    }
  }

  render() {
    const { goto, hasGoneTo, ...restProps } = this.props;
    return <RefItem {...restProps} ref={this.itemRef} />;
  }
}

export default connect(
  (state, { varenr }) => ({
    goto: shouldGotoVare(state, varenr),
    highlighted: isHighlighted(state, varenr),
  }),
  (dispatch, { varenr }) => ({
    hasGoneTo: () => dispatch(hasGoneToVare(varenr)),
  }),
)(ListItem);
