import React from 'react';
import styled from 'styled-components/macro';
import getArg from '../lib/args';
import { FadoelsBeregnerPopupLink } from './FadoelsBeregner';
import UbrudteFustagerModalLink from './UbrudteFustagerModalLink';
import { Span } from './typography';
import UbrudteFustager from './UbrudteFustager';
import HoldbarhedPopupLink from './HoldbarhedPopupLink';
import { BsCalculatorFill, BsCalendarDate, BsRecycle } from './Icons';

const StyledIcon = styled.div`
  padding-top: 5px;
  & > svg {
    font-size: 40px;
    fill: ${({ theme }) => theme.colors.colorGreen};
  }

  @media (max-width: 1599px) {
    & > svg {
      font-size: 30px;
    }
  }
`;

const Icons = ({ recycle, calculator, calendar }) => (
  <React.Fragment>
    {recycle && <StyledIcon><BsRecycle /></StyledIcon>}
    {calculator && <StyledIcon><BsCalculatorFill /></StyledIcon>}
    {calendar && <StyledIcon><BsCalendarDate /></StyledIcon>}
  </React.Fragment>
);

const ButtonsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  @media (max-width: 1599px) {
    grid-gap: 8px;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const basicStyles = ({ theme }) => `
  color:  ${theme.colors.colorGreyDark};
  background-color: ${theme.colors.colorGreyLight};
  border: 1px solid ${theme.colors.colorGreyLight};
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color:  ${theme.colors.colorGreyDark};
    text-decoration: none;
  }

  &:hover {
    border: 1px solid ${theme.colors.colorBlue};
  }

  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;

  @media (max-width: 1599px) {
    gap: 5px;
  }

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    padding: 5px 5px 10px 5px;
  }

  @media (max-width: 767px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }
`;
const UbrudteFustagerLink = styled(UbrudteFustagerModalLink)`
  ${basicStyles};

  @media (max-width: 767px) {
    grid-column: 1 / 3;
  }
`;

const FadoelsBeregnerLink = styled(FadoelsBeregnerPopupLink)`
  ${basicStyles};

  @media (max-width: 575px) {
    grid-column: 1 / 3;
  }
`;

const HodldbarhedLink = styled(HoldbarhedPopupLink)`
  ${basicStyles};

  @media (max-width: 575px) {
    grid-column: 1 / 3;
  }
`;

const Title = styled(Span)`
  display: block;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 1199px) {
    font-size: 15px;
  }
  ${({ hide }) => hide && 'display: none;'}
`;

const Text = styled(Span)`
  display: block;
  margin-top: 5px;
`;

const getHash = () => {
  if (getArg('serverRender') || typeof window === 'undefined') {
    return '';
  }
  const hash = window.location.hash;
  return hash || '';
};
export default () => (
  <ButtonsWrap>
    <UbrudteFustagerLink>
      <Icons recycle />
      <div>
        <Title>Ubrudte fustager retur</Title>
        <Text><UbrudteFustager /></Text>
      </div>
    </UbrudteFustagerLink>
    <FadoelsBeregnerLink initialyOpen={getHash() === '#fadoelsberegner'}>
      <Icons calculator />
      <div>
        <Title>Fadølsberegner</Title>
        <Text>Beregn hvor mange haner og hvor meget øl du skal bruge</Text>
      </div>
    </FadoelsBeregnerLink>
    <HodldbarhedLink initialyOpen={getHash() === '#holdbarhed'}>
      <Icons calendar />
      <div>
        <Title>Holdbarhed på fadøl</Title>
        <Text>Hvor lang er holdbarheden før / efter fustagen er åbnet?</Text>
      </div>
    </HodldbarhedLink>
  </ButtonsWrap>
);
